import { template as template_288b6ffb4a9a4d45baba2518379cf312 } from "@ember/template-compiler";
const FKLabel = template_288b6ffb4a9a4d45baba2518379cf312(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
